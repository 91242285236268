import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Colin Reeves will give the plenary session talk on the final day of the
Brazilian Geological  Congress. The congress will be held in Porto Alegre,
Brazil from October 9th to 13th. The congress  marks the 70th anniversary of the
foundation of the Geological Society of Brazil. `}</p>
    <p><a parentName="p" {...{
        "href": "http://www.48cbg.com.br/"
      }}>{`http://www.48cbg.com.br/`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://fellinievents.com.br/en/eventos/48o-congresso-brasileiro-de-geologia/"
      }}>{`http://fellinievents.com.br/en/eventos/48o-congresso-brasileiro-de-geologia/`}</a></p>
    <p>{`The title of the plenary talk is `}<strong parentName="p">{`Gondwana: How it fits together and how it came
apart.`}</strong>{` The  talk will feature explanation of the latest work on `}<strong parentName="p">{`Gondwana`}</strong>{`
re-assembly and the history of its  dispersal, as set out on the Gondwana page
of this website. The work forms part of IGCP-628 the  Gondwana geological map,
the latest version of which will be on show at the 35th International
Geological Congress in Cape Town, August 29-September 3. A sample re-assembly
using data  from an earlier version of the new map appears below. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      